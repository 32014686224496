<template>
  <main class="mt-5">
    <transition>
      <div v-if="loading" class="d-flex justify-content-center w-100 mt-3">
        <Loading />
      </div>
      <div v-else>
        <div class="card mt-3">
          <div class="card-header border-0 d-flex justify-content-between">
            <h5 class="card-title">سود محصول</h5>
            <b-button
              v-if="items.length == 0"
              @click="addRow()"
              variant="primary"
              >افزودن سطر</b-button
            >
          </div>
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <th>ردیف</th>
                  <th>از قیمت</th>
                  <th>تا قیمت</th>
                  <th>قیمت افزوده</th>
                  <th>تغییرات</th>
                </tr>
                <tr v-for="(item, index) in items" :key="index">
                  <th>
                    {{ index + 1 }}
                  </th>
                  <td>
                    <b-form-input v-model="item.yuan_from"> </b-form-input>
                  </td>
                  <td>
                    <b-form-input
                      v-if="items.length - 1 != index"
                      v-model="item.yuan_to"
                    >
                    </b-form-input>
                    <span v-else>بی نهایت</span>
                  </td>
                  <td>
                    <b-form-input v-model="item.price"> </b-form-input>
                  </td>
                  <td>
                    <div class="d-flex" style="gap: 8px">
                      <b-button variant="danger" @click="deleteRow(index)">
                        <i class="fa fa-trash"></i>
                      </b-button>
                      <b-button
                        v-if="index == items.length - 1"
                        @click="addRow()"
                        variant="primary"
                        >افزودن سطر</b-button
                      >
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
            <b-button :disabled="disable" @click="submit()" variant="primary">
              ثبت تغییرات
            </b-button>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import productsProfitImpl from "@@/core/pages/product/productsProfit/productsProfitImpl";
export default {
  extends: productsProfitImpl,
};
</script>
<style scoped>
.selectWeight {
  max-width: 320px;
}
</style>
