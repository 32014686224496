<template>
  <div class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">قیمت گذاری</h5>
    </div>

    <div class="card-body priceSection">
      <InputGroup label=" انبار ایران">
        <b-form-checkbox v-model="realValue.is_iran_store"></b-form-checkbox>
      </InputGroup>
      <InputGroup
        col1="3"
        col2="9"
        v-show="realValue.is_iran_store"
        required
        label="موجودی"
      >
        <b-form-input
          v-model="realValue.quantity"
          type="number"
          min="0"
          placeholder="موجودی"
        />
      </InputGroup>
      <InputGroup
        col1="3"
        col2="9"
        v-show="!realValue.is_iran_store"
        required
        label="وزن"
      >
        <treeselect
          :flat="true"
          :searchable="true"
          :multiple="false"
          :options="weights"
          noChildrenText="فرزندی وجود ندارد"
          noOptionsText="موردی وجود ندارد"
          noResultsText="موردی یافت نشد"
          placeholder="انتخاب وزن"
          v-model="realValue.weight"
        />
      </InputGroup>
      <InputGroup
        v-show="realValue.is_iran_store"
        required
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت خرید"
      >
        <b-form-input
          :value="realValue.purchasePrice | priceinput"
          @input="
            (value) => {
              realValue.purchasePrice =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          type="text"
          placeholder="قیمت خرید"
        />
      </InputGroup>
      <InputGroup
        v-show="!realValue.is_iran_store"
        required
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت یوان"
      >
        <b-form-input
          :value="realValue.yuan_price"
          @input="
            (value) => {
              realValue.yuan_price = value;
            }
          "
          type="text"
          placeholder="قیمت به یوان"
        />
      </InputGroup>
      <InputGroup
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت  همکار"
        v-show="realValue.is_iran_store"
        required
      >
        <b-form-input
          :value="realValue.unitPrice | priceinput"
          @input="
            (value) => {
              realValue.unitPrice =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          placeholder="قیمت  همکار"
          type="text"
        />
      </InputGroup>
      <InputGroup
        v-show="realValue.is_iran_store"
        required
        label-padding="0px"
        col1="3"
        col2="9"
        label="قیمت فروش "
      >
        <b-form-input
          :value="realValue.sellPrice | priceinput"
          @input="
            (value) => {
              realValue.sellPrice =
                value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          type="text"
          placeholder="قیمت فروش "
        />
      </InputGroup>
      <!-- <InputGroup label-padding="0px" col1="3" col2="9" label="قیمت سهم شاهین کالا">
        <b-form-input :value="realValue.sharePrice | priceinput" @input="value=>{realValue.sharePrice = value !== '' ? +value.replace(/\D/g, '') : ''}"
                      type="text" placeholder="قیمت سهم شاهین کالا"/>
      </InputGroup> -->

      <InputGroup label-padding="0px" col1="3" col2="9" label="تخفیف">
        <Discount v-model="realValue" />
      </InputGroup>
    </div>
  </div>
</template>

<script>
import ProductPricingImpl from "@@/core/components/product/small-cards/ProductPricing/ProductPricingImpl";
export default {
  extends: ProductPricingImpl,
};
</script>
<style>
.priceSection label {
  display: block;
  width: max-content;
}
</style>
