import Back from '@@/core/components/shared/Back/Back'
import CategoryForm from '@@/core/components/category/CategoryForm/CategoryForm'

    export default {
        components: {
            Back,
            CategoryForm
        },
        data() {
            return {
                title: '',
                url: '',
                axiosCancelSource: null,
                category: {
                    title:'',
                    description:'',
                    parent_id: 0,
                    status:1,
                    special:0,
                    meta_title:'',
                    meta_description:'',
                    attributes: null,
                    specifications: null,
                    brands: null,
                    universal_is_acceptable:1
                },
                categories: [],
                attributes: [],
                specifications: [],
                brands:[]
            }
        },
        mounted() {
            this.loadCategories()
            this.loadAttributes()
            this.loadSpecifications()
            this.loadBrands()
            if (this.$route.params.id) {
                this.url = 'admin/categories/' + this.$route.params.id
                this.getCategory()
            } else {
                this.$refs.categoryForm.setDisabled(false)
                this.url = 'admin/categories'
            }
        },
        methods: {
            async loadCategories() {
                try {
                    const res = await this.$axios.get('admin/categories?all=1')
                    this.categories = res.data.data.categories
                    this.$route.params.id ? this.categories = this.categories.filter(item => item.id != this.$route.params.id) : ''
                } catch (error) {
                    this.$root.notify(error)
                }
            },
            async loadAttributes() {
                try {
                    const res = await this.$axios.get('admin/attributes?all=1')
                    this.attributes = res.data.data.attributes
                } catch (error) {
                    this.$root.notify(error)
                }
            },
            async loadSpecifications() {
                try {
                    const res = await this.$axios.get('admin/specifications?all=1')
                    this.specifications = res.data.data.specifications
                } catch (error) {
                    this.$root.notify(error)
                }
            },
            async loadBrands() {
                try {
                    const res = await this.$axios.get('admin/brands?all=1')
                    this.brands = res.data.data.brands
                } catch (error) {
                    this.$root.notify(error)
                }
            },
            async getCategory() {
                try {
                    this.axiosCancelSource = this.$axios.CancelToken.source();
                    const response = await this.$axios.get(this.url, {
                        cancelToken: this.axiosCancelSource.token
                    })
                    this.category = response.data.data.category
                    this.$refs.categoryForm.setDisabled(false)
                } catch (error) {
                    if (this.$axios.isCancel(error)) {
                        return;
                    }
                    this.$root.notify(error)
                }
            }
        }
    }
