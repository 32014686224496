import "@@/core/assets/css/print.css";
import { Printd } from "printd";
import styles from "./getStyles";
import configProvider from "@@/core/configProvider";
import { BTable } from "bootstrap-vue";
import Loading from "@@/core/components/shared/Loading/Loading";
export default {
  components: {
    BTable,
    Loading,
  },
  props: {
    order: Object,
    settings: Array,
  },
  configProvider,
  data() {
    return {
      showPrices: true,

      fields: [
        {
          key: "image",
          label: "تصویر",
        },
        {
          key: "product",
          label: "محصول",
        },

        {
          key: "amount",
          label: "قیمت ",
        },
        {
          key: "discount_amount",
          label: "تخفیف",
        },
        {
          key: "quantity",
          label: "تعداد",
        },
        {
          key: "total_price",
          label: "مبلغ کل",
        },
        // {
        //   key: "note",
        //   label: "یادداشت خرید",
        // },
      ],
      fields1: [
        {
          key: "product",
          label: "محصول",
        },

        // {
        //   key: "amount",
        //   label: "قیمت ",
        // },
        // {
        //   key: "discount_amount",
        //   label: "تخفیف",
        // },
        {
          key: "quantity",
          label: "تعداد",
        },
        // {
        //   key: "total_price",
        //   label: "مبلغ کل",
        // },
        {
          key: "note",
          label: "یادداشت خرید",
        },
      ],
    };
  },
  watch: {
    order: {
      immediate: true,
      handler() {
        if (this.order) {
          this.order.active_items = this.order.items.filter((i) => i.status);
        }
      },
    },
  },
  computed: {
    items() {
      let items = [];
      // this.order.
    },
    invoice() {
      let invoice = this.order.invoices.filter(
        (invoice) => invoice.status == "success"
      );
      if (invoice != "") {
        return invoice[0];
      } else {
        return null;
      }
    },
    invoicePayment() {
      let invoicePayment = this.invoice
        ? this.invoice.payments.filter((payment) => payment.status == "success")
        : [];
      if (invoicePayment != "") {
        return invoicePayment[0];
      } else {
        return null;
      }
    },
    address() {
      return JSON.parse(this.order.address);
    },
    total() {
      return this.order.active_items.reduce((sum, item) => {
        sum += item.amount * item.quantity;
        return sum;
      }, 0);
    },
    productsDiscount() {
      return this.order.active_items.reduce((sum, item) => {
        sum += item.quantity * item.discount_amount;
        return sum;
      }, 0);
    },
    totalPrice() {
      if (this.order.discount_amount) {
        return (
          this.total + this.order.shipping_amount - this.order.discount_amount
        );
      } else {
        return this.total + this.order.shipping_amount;
      }
    },
  },
  methods: {
    getImage(item) {
      console.log("iteems:", item);
      console.log("items title:", item.product.title);

      let defaultImage = item.product.images[0].url;

      // if (item.variety.images.length) {
      //     return defaultImage;
      // }
      if (item.variety.images.length) {
        return item.variety.images[0].url;
      } else {
        let method = "";

        if (item.variety.color) {
          method = "color";
        } else if (item.variety.attributes.length == 2) {
          method = "tarh";
        } else {
          method = item.variety.attributes[0].name;
        }

        if (method == "color") {
          let finded = item.product.varieties.find((v) => {
            // v.color    added
            if (
              v.color &&
              v.color.id == item.variety.color.id &&
              v.images.length
            ) {
              return v;
            }
          });
          return finded ? finded.images[0].url : defaultImage;
        } else {

          // let mainAttr = item.variety.attributes.find((att) => {
          //     return att.name == method;
          // }).pivot.value;

          let mainAttr = item.variety.attributes.find((att) => {
            return att.name == method;
          });

          if (mainAttr && mainAttr.pivot) {
            mainAttr = mainAttr.pivot.value;

            let findedVariety = item.product.varieties.find((v) => {
              let findedAttr = v.attributes.find((attr) => {
                return attr.name == method && attr.pivot.value == mainAttr;
              });
              if (findedAttr && v.images.length) {
                return v;
              }
            });

            return findedVariety ? findedVariety.images[0].url : defaultImage;
          } else {
            // else add
            return defaultImage;
          }
        }
      }
    },
    orderPrint() {
      const d = new Printd();
      let _styles;
      if (configProvider.get("print_font")) {
        _styles =
          styles +
          "@font-face{font-family:'yekan-bakh';src:url(" +
          configProvider.get("print_font") +
          ") format('woff');font-weight:normal;font-style:normal;}";
      } else {
        _styles = styles;
      }
      d.print(document.getElementById("print"), [_styles]);
    },
  },
  mounted() {
    this.showPrices =
      this.order.sell_type == "virtual" ||
      this.order.sell_type == "sajadWebsite"
        ? true
        : false;
  },
};
