import Loading from "@@/core/components/shared/Loading/Loading";
import { BButton, BFormInput } from "bootstrap-vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "Product",
  components: {
    Loading,
    BButton,
    BFormInput,
    Treeselect,
  },
  data() {
    return {
      items: [],
      weights: [],
      disable: false,
      loading: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let res = await this.$axios.get("admin/product-weight-ranges/create");
      let list = [];
      res.data.data.acceptable_product_weights.forEach((item) => {
        let obj = {
          id: item,
          label: item,
        };
        list.push(obj);
      });
      this.weights = list;
      console.log(this.weights);
      let res2 = await this.$axios.get("admin/product-weight-ranges");
      let list2 = [];
      this.items = res2.data.data.allProductWeights;
      this.items.forEach((item) => {
        list2.push({
          weight_from: this.weights.find(
            (w) => w.id == Number(item.weight_from)
          ).id,
          weight_to: this.weights.find((w) => w.id == Number(item.weight_to))
            .id
            ,
          price: item.price,
        });
      });
      this.items = list2;
      this.loading = false;
    },
    addRow() {
      this.items.push({
        price: 0,
        weight_from:null,
        weight_to: null,
      });
    },
    deleteRow(index) {
      this.items.splice(index, 1);
    },
    submit() {
      this.disable = true;
      let fd = new FormData();
      fd.append("_method","PUT")
      this.items.forEach((item, index) => {
        fd.append(`ranges[${index}][weight_from]`, item.weight_from);
        fd.append(`ranges[${index}][weight_to]`, item.weight_to);
        fd.append(`ranges[${index}][price]`, item.price);
      });
      this.$axios
        .post("admin/product-weight-ranges", fd)
        .then((res) => {
          this.disable = false;
          this.$root.notify(res, "success");
        })
        .catch((err) => {
          this.disable = false;
          this.$root.notify(err);
        });
    },
  },
};
