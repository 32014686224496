<template>
  <form id="settingForm" @submit.prevent="submit">
    <div class="row">
      <b-form-group
        v-for="item in settings"
        :key="item.id"
        :class="item.type == 'text' ? 'col-md-6' : 'col-md-6'"
      >
        <label for=""> {{ item.label }} </label>
        <Ckeditor
          v-if="item.type === 'editor'"
          :id="`editor-${item.name}`"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        />
        <b-form-input
          v-if="item.type == 'string' || item.type == 'number'"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        >
        </b-form-input>
        <b-form-input
          v-if="item.type == 'integer'"
          type="number"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        ></b-form-input>
        <template v-else-if="item.type == 'image'">
          <img src="" alt="" />
          <b-form-file
            placeholder="فایلی انتخاب نشده"
            :name="item.name"
            :disabled="disabled"
          ></b-form-file>
        </template>
        <template v-else-if="item.type == 'file'">
          <img src="" alt="" />
          <b-form-file
            placeholder="فایلی انتخاب نشده"
            :name="item.name"
            :disabled="disabled"
          ></b-form-file>
        </template>
        <b-form-textarea
          v-else-if="item.type == 'text'"
          :name="item.name"
          :value="item.value"
          :disabled="disabled"
        ></b-form-textarea>
        <b-form-input
          v-else-if="item.type == 'price'"
          :value="item.value | priceinput"
          @input="
            (value) => {
              item.value = value !== '' ? +value.replace(/\D/g, '') : '';
            }
          "
          :name="item.name"
          :disabled="disabled"
        >
        </b-form-input>
        <b-form-checkbox
          v-else-if="item.type == 'boolean'"
          v-model="item.value"
          :name="item.name"
          value="1"
          unchecked-value="0"
          :disabled="disabled"
        >
        </b-form-checkbox>
        <multiselect
          v-else-if="item.type === 'multi_select'"
          v-model="item.value"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="products"
          label="label"
          track-by="id"
          :searchable="true"
          :multiple="false"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
          @input="setVarieties"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
      </b-form-group>
    </div>
    <div class="text-center">
      <button :disabled="disabled" type="submit" class="btn btn-success">
        اعمال تغییرات
      </button>
    </div>
  </form>
</template>
<script>
import SettingFormImpl from "@@/core/components/settings/SettingForm/SettingFormImpl";
import Ckeditor from "@/core/components/shared/Ckeditor/Ckeditor.vue";

export default {
  components: { Ckeditor },
  extends: SettingFormImpl,
};
</script>
