<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش دسته بندی" : "دسته بندی جدید" }}
        </h5>
        <div style="flex: 1"></div>
        <Back />
      </div>
      <div class="card-body">
        <CategoryForm
          ref="categoryForm"
          :mode="$route.params.id ? 'edit' : 'create'"
          :url="url"
          :category="category"
          :options="categories"
          :attributes="attributes"
          :specifications="specifications"
          :brands="brands"
        />
      </div>
    </div>
  </main>
</template>
<script>
import CategoryCreateEditImpl from "@@/core/pages/category/CategoryCreateEdit/CategoryCreateEditImpl";
export default {
  extends: CategoryCreateEditImpl,
};
</script>
