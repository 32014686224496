import Loading from "@@/core/components/shared/Loading/Loading";
import mixins from "@@/core/mixins/mixins";
import Swal from "sweetalert2";
import {
  BTooltip,
  BButton,
  BCard,
  BTable,
  BCollapse,
  BFormGroup,
  BFormInput,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
export default {
  name: "Products",
  mixins: [mixins],
  components: {
    Loading,
    BTooltip,
    BButton,
    BCard,
    BTable,
    BCollapse,
    BFormGroup,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      search: "",
      url: "admin/userSajad",
      widgets: ["categories"],
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "fullName",
          label: "نام کامل",
        },
        {
          key: "mobile",
          label: "شماره تماس",
        },

        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "seen",
          label: "مشاهده",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
          key: "delete",
          label: "حذف",
        },
      ],
      showingCategories: [],
      statusOptions: [
        {
          value: "available",
          name: "موجود",
        },
        {
          value: "soon",
          name: "بزودی",
        },
        {
          value: "out_of_stock",
          name: "ناموجود",
        },
        {
          value: "draft",
          name: "پیش نویس",
        },
        {
          value: "",
          name: "همه",
        },
      ],
      approveOptions: [
        {
          name: "تایید شده",
          value: "1",
        },
        {
          name: "تایید نشده",
          value: "0",
        },
        {
          name: "همه",
          value: "",
        },
      ],
      filter: {
        id: "",
        title: "",
        category_id: "",
        status: "",
        approved_at: "",
      },
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    resetFilter(){
      this.search="";
      this.searchParams={}
    },
    filterItem() {
      this.searchParams = {
        search: this.search,
      };
    },
  },
};
