<template>
  <div v-if="order" class="print-order px-2 mx-0 pagebreak">
    <table class="table print-customer-table mt-2">
      <tbody>
        <tr v-if="settings != ''" class="print-order-shop">
          <td class="print-order-item-title">
            <span class="text-center"> فروشگاه </span>
          </td>
          <td
            class="print-order-item-info text-right"
            style="padding-right: 5px !important"
          >
            <div
              class="print-order-item-address pb-lg-1 pt-1 align-items-center"
            >
              <div style="flex-shrink: 0">
                <span
                  v-if="order.customer.shop_name"
                  class="font-weight-bold fontsize15 mb-0"
                >
                  عنوان :
                </span>
                <span
                  v-if="order.customer.shop_name"
                  class="mb-0 fontsize14 pl-2"
                >
                  {{ order.customer.shop_name }}
                </span>
                <span class="font-weight-bold fontsize15 mb-0">
                  شماره تماس:
                </span>
                <span>
                  {{ order.customer.mobile }}
                </span>
                <span
                  v-if="order.customer.main_postal_code"
                  class="font-weight-bold fontsize15 mb-0"
                >
                  کدپستی :
                </span>
                <span v-if="order.customer.main_postal_code">
                  {{ order.customer.main_postal_code }}
                </span>
                <span
                  v-if="order.customer.main_address"
                  class="font-weight-bold fontsize15 mb-0"
                >
                  آدرس:
                </span>
                <span v-if="order.customer.main_address">
                  {{ order.customer.main_address }}
                </span>
              </div>
            </div>
          </td>
        </tr>
        <tr class="print-order-shop">
          <td class="print-order-item-title">
            <span class="text-center"> خریدار </span>
          </td>
          <td
            class="print-order-item-info px-1 text-right"
            style="line-height: 20px !important"
          >
            <div class="pt-0">
              <span class="font-weight-bold fontsize15 mb-0">
                نام کامل :
                <span
                  class="mb-0 pr-1 fontsize14 pl-2"
                  style="font-weight: 500"
                >
                  {{ address.first_name }} {{ address.last_name }}
                </span>
              </span>
            </div>
            <div class="d-flex print-order-item-address align-items-center">
              <div>
                <span class="font-weight-bold fontsize14 mb-0"> گیرنده : </span>
                <span class="mb-0 pr-1 fontsize13 pl-1">
                  {{
                    address.city
                      ? address.city.province
                        ? address.city.province.name
                        : ""
                      : ""
                  }}
                  -
                  {{
                    address.city ? (address.city ? address.city.name : "") : ""
                  }}
                  -
                  {{ address.address }}
                </span>
              </div>
            </div>
            <div class="">
              <span class="font-weight-bold fontsize13 mb-0"> کدپستی: </span>
              <span class="mb-0 fontsize14 pl-2">
                {{ address.postal_code }}
              </span>
              <span class="font-weight-bold fontsize13 mb-0"> تلفن: </span>
              <span class="mb-0 fontsize14 pl-2">
                {{ address.mobile }}
              </span>
              <!--              <span v-if="invoice" class="print-order-invoice">-->
              <!--                <span class="font-weight-bold fontsize15 mb-0">-->
              <!--                  روش پرداخت:-->
              <!--                </span>-->
              <!--                <span-->
              <!--                  v-if="invoice.type == 'gateway'"-->
              <!--                  class="mb-0 fontsize14 pl-2"-->
              <!--                >-->
              <!--                  درگاه {{ invoicePayment ? invoicePayment.gateway_label : "" }}-->
              <!--                </span>-->
              <!--                <span-->
              <!--                  v-else-if="invoice.type == 'wallet'"-->
              <!--                  class="mb-0 fontsize14 pl-2"-->
              <!--                >-->
              <!--                  کیف پول-->
              <!--                </span>-->
              <!--              </span>-->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      style="
        border-bottom: 1px solid;
        padding-bottom: 2px;
        margin-bottom: 4px;
        display: flex;
        justify-content: space-between;
      "
    >
      <div v-if="order.shipping">روش ارسال: {{ order.shipping.name }}</div>
      <div>
        <span>شناسه سفارش: </span><span>{{ order.id }}</span>
      </div>

      <div v-if="order">
        {{ new Date(order.created_at).toLocaleDateString("fa") }}
      </div>
    </div>
    <div v-if="order.description" class="card">
      <div class="card-header">توضیحات:</div>
      <div class="card-body">
        {{ order.description }}
      </div>
    </div>
    <b-table
      responsive
      :items="order.active_items"
      :fields="showPrices ? fields : fields1"
      bordered
      class="table print-customer-order-table mt-2"
    >
      <template v-slot:cell(image)="data">
        <!-- عکس محصول  -->
        <img :src="getImage(data.item)" width="48" />
      </template>
      <template v-slot:cell(product)="data">
        <p style="line-height: 2" class="mb-0">
          {{ data.item.product.title }}
          <span
            v-if="data.item.extra.color || data.item.extra.attributes != ''"
            class="mr-1"
          >
          </span>
          <span v-if="data.item.extra.color != null">
            رنگ : {{ data.item.extra.color }}
            {{
              data.item.extra.color && data.item.extra.attributes != ""
                ? " | "
                : ""
            }}
          </span>
          <span v-if="data.item.extra.attributes != ''">
            <span
              v-for="(attr, index) in data.item.extra.attributes"
              :key="index"
            >
              {{ attr.label + ":" + attr.pivot.value }}
              {{ index != data.item.extra.attributes.length - 1 ? " | " : "" }}
            </span>
          </span>
        </p>
      </template>

      <template v-slot:cell(amount)="data">
        <span v-if="data.item.amount != 0"
          >{{ (data.item.amount + data.item.discount_amount) | price }} تومان
        </span>
        <span v-else>0</span>
      </template>
      <template v-slot:cell(quantity)="data">
        <span style="font-weight: bold">{{ data.item.quantity }}</span>
      </template>
      <template v-slot:cell(discount_amount)="data">
        <span v-if="data.item.discount_amount"
          >{{ data.item.discount_amount | price }} تومان</span
        >
        <span v-else>ندارد</span>
      </template>
      <template v-slot:cell(total_price)="data">
        <span v-if="data.item.amount != 0"
          >{{ (data.item.amount * data.item.quantity) | price }} تومان
        </span>
        <span v-else>0</span>
      </template>
    </b-table>
    <div v-if="showPrices" class="d-flex justify-content-end">
      <table
        class="table print-order-total-table text-right"
        style="width: 400px"
      >
        <tbody>
          <tr class="print-order-shop">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ کل</span>
            </td>
            <td class="">{{ total | price }} تومان</td>
          </tr>
          <tr class="print-order-shop">
            <td class="py-2">
              <span class="font-weight-bold px-2">هزینه حمل و نقل</span>
            </td>
            <td class="">
              <span v-if="order.shipping_amount != 0">
                {{ order.shipping_amount | price }} تومان
              </span>
              <span v-else class=""> پس کرایه درب منزل </span>
            </td>
          </tr>
          <tr class="print-order-shop">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ تخفیف محصولات</span>
            </td>
            <td class="">
              <span> {{ productsDiscount | price }} تومان </span>
            </td>
          </tr>
          <tr v-if="order.coupon" class="print-order-shop couponBg">
            <td class="py-2">
              <span class="font-weight-bold px-2">کدتخفیف:</span>
            </td>
            <td class="">
              <span>
                {{ order.coupon.code }}
              </span>
            </td>
          </tr>
          <tr v-if="order.discount_amount" class="print-order-shop">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ تخفیف فاکتور</span>
            </td>
            <td class="">
              <span v-if="order.discount_amount != 0">
                {{ order.discount_amount | price }} تومان
              </span>
              <span v-else class=""> 0 </span>
            </td>
          </tr>

          <tr class="print-order-shop">
            <td class="py-2">
              <span class="font-weight-bold px-2">مبلغ پرداختی</span>
            </td>
            <td class="">{{ totalPrice | price }} تومان</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-center print-order-btn mb-4">
      <button class="btn btn-print text-white" @click="orderPrint()">
        چاپ فاکتور
      </button>
      <!-- <button class="btn btn-print text-white mr-1">بازگشت</button> -->
    </div>
  </div>
  <Loading v-else />
</template>
<script>
import OrderPrintItemImpl from "@@/core/components/order/OrderPrintItem/OrderPrintItemImpl";
export default {
  extends: OrderPrintItemImpl,
};
</script>
<style
  scoped
  src="@@/core/components/order/OrderPrintItem/OrderPrintItemStyle.css"
></style>
<style>
tr.print-order-shop.couponBg {
  background: #e6dfd3;
}
</style>
