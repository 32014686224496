import Loading from "@@/core/components/shared/Loading/Loading";
import { BButton, BFormInput } from "bootstrap-vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "Product",
  components: {
    Loading,
    BButton,
    BFormInput,
    Treeselect,
  },
  data() {
    return {
      items: [],
      weights: [],
      disable: false,
      loading: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let res = await this.$axios.get("admin/product-profit-ranges");
      this.items = res.data.data.allProductProfits;
      this.loading = false;
    },
    addRow() {
      this.items.push({
        price: 0,
        yuan_from: null,
        yuan_to: null,
      });
    },
    deleteRow(index) {
      this.items.splice(index, 1);
    },
    submit() {
      this.disable = true;
      let fd = new FormData();
      fd.append("_method", "PUT");
      this.items.forEach((item, index) => {
        fd.append(`ranges[${index}][yuan_from]`, item.yuan_from);
        fd.append(`ranges[${index}][yuan_to]`, item.yuan_to??"");
        fd.append(`ranges[${index}][price]`, item.price);
        fd.append(
          `ranges[${index}][is_infinity]`,
          index == this.items.length - 1 ? 1 : 0
        );
      });
      this.$axios
        .post("admin/product-profit-ranges", fd)
        .then((res) => {
          this.disable = false;
          this.$root.notify(res, "success");
        })
        .catch((err) => {
          this.disable = false;
          this.$root.notify(err);
        });
    },
  },
};
