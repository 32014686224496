<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-6">
        <b-form-group>
          <label for="name"> عنوان <i class="text-danger">*</i></label>
          <b-form-input
            v-model="category.title"
            name="title"
            type="text"
            placeholder="عنوان"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group>
          <label for="name">
            عنوان (انگلیسی) <i class="text-danger">*</i></label
          >
          <b-form-input
            v-model="category.en_title"
            name="en_title"
            type="text"
            placeholder="عنوان"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="پدر">
          <treeselect
            :options="realOptions"
            v-model="parentId"
            :searchable="true"
            :show-count="true"
            noChildrenText="فرزندی وجود ندارد"
            noOptionsText="موردی وجود ندارد"
            noResultsText="موردی یافت نشد"
            :default-expand-level="1"
            label="title"
          >
            <label
              slot="option-label"
              slot-scope="{
                node,
                shouldShowCount,
                count,
                labelClassName,
                countClassName,
              }"
              :class="labelClassName"
            >
              {{ node.label }}
              <span
                v-show="false"
                v-if="shouldShowCount"
                :class="countClassName"
                >({{ count }})</span
              >
            </label>
          </treeselect>
        </b-form-group>
        <input
          type="hidden"
          name="parent_id"
          :value="parentId != 0 ? parentId : ''"
        />
      </div>

      <div class="col-md-6">
        <b-form-group label="ویژگی">
          <multiselect
            v-model="category.attributes"
            open-direction="bottom"
            placeholder="انتخاب کنید"
            :options="attributes"
            label="name"
            track-by="id"
            :searchable="true"
            :multiple="true"
            :close-on-select="false"
            :show-labels="false"
            :disabled="disabled"
          >
            <template slot="noOptions"> موردی یافت نشد </template>
            <template slot="noResult"> موردی یافت نشد </template>
          </multiselect>
        </b-form-group>
        <template v-if="category.attributes != null">
          <input
            v-for="(attr, index) in category.attributes"
            :key="attr.id"
            type="hidden"
            :name="'attribute_ids[' + index + ']'"
            :value="attr.id"
          />
        </template>
      </div>
      <div class="col-md-6">
        <b-form-group label="مشخصات">
          <multiselect
            v-model="category.specifications"
            placeholder="انتخاب کنید"
            open-direction="bottom"
            :options="specifications"
            label="name"
            track-by="id"
            :searchable="true"
            :multiple="true"
            :close-on-select="false"
            :show-labels="false"
            :disabled="disabled"
          >
            <template slot="noOptions"> موردی یافت نشد </template>
            <template slot="noResult"> موردی یافت نشد </template>
          </multiselect>
        </b-form-group>
        <template v-if="category.specifications != null">
          <input
            v-for="(item, index) in category.specifications"
            :key="item.id"
            type="hidden"
            :name="'specification_ids[' + index + ']'"
            :value="item.id"
          />
        </template>
      </div>
      <div class="col-md-6">
        <b-form-group label="برند">
          <multiselect
            v-model="category.brands"
            placeholder="انتخاب کنید"
            open-direction="bottom"
            :options="brands"
            label="name"
            track-by="id"
            :searchable="true"
            :multiple="true"
            :close-on-select="false"
            :show-labels="false"
            :disabled="disabled"
          >
            <template slot="noOptions"> موردی یافت نشد </template>
            <template slot="noResult"> موردی یافت نشد </template>
          </multiselect>
        </b-form-group>
        <template v-if="category.brands != null">
          <input
            v-for="(item, index) in category.brands"
            :key="item.id"
            type="hidden"
            :name="'brand_ids[' + index + ']'"
            :value="item.id"
          />
        </template>
      </div>
      <div class="col-md-12">
        <b-form-group label="توضیح">
          <b-form-textarea
            v-model="category.description"
            name="description"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="col-md-12">
        <b-form-group label="متا">
          <b-form-input
            v-model="category.meta_title"
            name="meta_title"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-12">
        <b-form-group label="توضیحات متا">
          <b-form-textarea
            v-model="category.meta_description"
            name="meta_description"
          ></b-form-textarea>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="تصویر">
          <b-form-file
            placeholder="فایلی انتخاب نشده"
            name="image"
          ></b-form-file>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label="آیکون">
          <b-form-file
            placeholder="فایلی انتخاب نشده"
            name="icon"
          ></b-form-file>
        </b-form-group>
      </div>
      <div class="col-md-3 mt-2">
        <b-form-checkbox
          id="status"
          :disabled="disabled"
          v-model="category.status"
          name="status"
          value="1"
          unchecked-value="0"
        >
          <span class="pr-5">وضعیت</span>
        </b-form-checkbox>
      </div>
      <div class="col-md-3 mt-2">
        <b-form-checkbox
          id="special"
          :disabled="disabled"
          v-model="category.special"
          name="special"
          value="1"
          unchecked-value="0"
        >
          <span class="pr-5">ویژه</span>
        </b-form-checkbox>
      </div>
      <div class="col-md-3 mt-2">
        <b-form-checkbox
          id="universal_is_acceptable"
          :disabled="disabled"
          v-model="category.universal_is_acceptable"
          name="universal_is_acceptable"
          value="1"
          unchecked-value="0"
        >
          <span class="pr-5">دسته بندی با شیوه ارسال universal</span>
        </b-form-checkbox>
      </div>
    </div>
    <div class="text-center mt-5">
      <button :disabled="disabled" type="submit" class="btn btn-success">
        {{ mode == "edit" ? " ویرایش دسته بندی" : " ثبت دسته بندی" }}
      </button>
    </div>
  </form>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import CategoryFormImpl from "@@/core/components/category/CategoryForm/CategoryFormImpl";
export default {
  components: {
    Treeselect,
  },
  extends: CategoryFormImpl,
};
</script>

<style scoped></style>
