import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";
import Discount from "@@/core/components/product/shared/Discount/Discount";
import { BFormInput, BFormCheckbox } from "bootstrap-vue";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "ProductPricing",
  components: { Discount, InputGroup, BFormCheckbox, BFormInput, Treeselect },
  props: {
    value: Object,
    weights: Array,
  },
  watch: {
    value(newVal) {
      this.realValue = newVal;
    },
    realValue(newVal) {
      this.$emit("input", newVal);
    },
  },
  data() {
    return {
      realValue: this.value,
    };
  },
};
