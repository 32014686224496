<template>
  <div>
    <b-form-group>
      <label slot="label">نام <span></span></label>
      <b-form-input v-model="realValue.name" type="text"
                    placeholder="نام"></b-form-input>
    </b-form-group>
    
    <b-form-group label="بارکد">
      <b-form-input v-model="realValue.barcode" type="text" placeholder="بارکد"></b-form-input>
    </b-form-group>
    <b-form-group label="هدایا">
      <Gift v-model="realValue.productGifts" :gifts="gifts" no-merge type="text" placeholder="بارکد" />
    </b-form-group>
    <b-form-group label="SKU">
      <b-form-input v-model="realValue.SKU" type="text" placeholder="SKU"></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import VarietyInformationImpl from '@@/core/components/product/varieties/VarietyInformation/VarietyInformationImpl'
import Gift from '@@/core/components/product/shared/Gift/Gift'

export default {
  components: {Gift},
  extends: VarietyInformationImpl
}
</script>

<style scoped>

</style>
